import {
  Box,
  Button,
  Form,
  FormField,
  Grommet,
  Heading,
  Page,
  PageContent,
  Text,
} from "grommet";
import React, { useState } from "react";
import { firebaseAuth } from "../../firebase";
import PublicHeader from "../Common/PublicHeader";
import { customTheme } from "../../theme";
import { Link } from "react-router-dom";

const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      await firebaseAuth.createUserWithEmailAndPassword(email, password);
      // Redirect to home page or other desired location after successful signup
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Grommet theme={customTheme} full>
      <Page fill>
        <PublicHeader />
        <PageContent>
          <Box
            direction="column"
            alignSelf="center"
            gap="medium"
            pad={{ top: "large", horizontal: "xlarge" }}
          >
            <Heading level={2}>Sign Up</Heading>
            <Form onSubmit={handleSubmit}>
              <FormField
                label="Email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
              <FormField
                label="Password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
                required
              />
              <FormField
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                type="password"
                required
              />
              {error && <Text color="status-critical">{error}</Text>}
              <Box direction="row" justify="end" gap="medium">
                <Button type="submit" label="Sign Up" primary />
                <Text>
                  Already have an account? <Link to="/login">Log in</Link>
                </Text>
              </Box>
            </Form>
          </Box>
        </PageContent>
      </Page>
    </Grommet>
  );
};

export default SignUpPage;
