import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";

export const customTheme = deepMerge(grommet, {
  name: "oriangi",
  rounding: 6,
  spacing: 24,
  global: {
    colors: {
      "calendar-available": "rgba(148, 216, 255, 0.35)",
      "calendar-selected": "rgba(148, 216, 255, 0.85)",
      brand: "#0B4F6C",
      "oriangi-pink": "#F7EFED",
      "nav-bar-background": "#FFFFF",
      "nav-bar-border": "#edede9",
      border: "#edede9",
      text: "#000000",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning",
      "status-booked": "#00A41A",
      "status-booking": "#FFBD12",
      "status-available": "#4378FF",
      "analytics-card-background": "#FFFFFF",
      "stack-analytics": "#7FA7B8",
    },
    font: {
      family: "Wix Madefor Text",
    },
    active: {
      background: "active-background",
      color: "active-text",
    },
    hover: {
      background: "active-background",
      color: "active-text",
    },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
    control: {
      border: {
        radius: "10px",
      },
    },
    drop: {
      border: {
        radius: "6px",
      },
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
  layer: {
    background: "#FFFFFF",
  },
  button: {
    default: {
      color: "text",
      padding: {
        horizontal: "10px",
        vertical: "10px",
      },
    },
    primary: {
      background: { color: "brand" },
      border: undefined,
      color: "#FFF",
      padding: {
        horizontal: "10px",
        vertical: "10px",
      },
    },
    secondary: {
      background: { color: "brand" },
      border: { color: "brand", width: "1px" },
      color: "text",
      padding: {
        horizontal: "10px",
        vertical: "10px",
      },
      font: {
        weight: "normal",
      },
    },
    active: {
      default: {
        color: "#00a6fb",
      },
      background: { color: "brand-contrast" },
      color: "text",
      secondary: {
        background: "none",
        border: {
          color: "brand-contrast",
        },
      },
    },
    disabled: {
      opacity: "0.4",
      color: "text",
      padding: {
        horizontal: "10px",
        vertical: "10px",
      },
    },
    hover: {
      default: {
        color: "#00a6fb",
      },
      background: { color: "active" },
      secondary: {
        border: { color: "active" },
      },
    },
  },
  checkBox: {
    check: {
      radius: "6px",
    },
    toggle: {
      radius: "6px",
    },
  },
  radioButton: {
    check: {
      radius: "6px",
    },
  },
  formField: {
    label: {
      margin: {
        horizontal: "0px",
        vertical: "small",
      },
      size: "small",
      weight: "bold",
    },
    disabled: {
      background: {
        color: "status-disabled",
        opacity: "medium",
      },
    },
    error: {
      color: "status-critical",
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
    },
    help: {
      color: "dark-3",
      margin: {
        start: "small",
      },
    },
    info: {
      color: "text-xweak",
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
    },
    margin: {
      bottom: "none",
    },
    border: false,
  },
  accordion: {
    border: {
      style: "hidden",
    },
  },
  anchor: {
    color: "black",
    hover: {
      textDecoration: "none",
      extend: `color: #00a6fb`,
    },
  },
  // notification: {
  //   container: {
  //     round: "xsmall",
  //     pad: { horizontal: "small", vertical: "xsmall" },
  //     background: {
  //       color: "rgba(255,255,255,0.6)",
  //     },
  //   },
  // },
  tab: {
    color: "text-strong",
    active: {
      background: "background-contrast",
    },
    border: {
      side: "bottom",
      color: "border",
      active: {
        color: "border-strong",
      },
      disabled: {
        color: "border-weak",
      },
      hover: {
        color: "border",
      },
    },
    disabled: {
      color: "text-weak",
    },
    hover: {
      background: "background-contrast",
      color: "text",
    },
    pad: "small",
    margin: {
      horizontal: "none",
    },
  },
});
