import { createSlice } from "@reduxjs/toolkit";

export const availabilitySlice = createSlice({
  name: "availability",
  initialState: [],
  reducers: {
    setAvailability: {
      reducer: (state, action) => (state = action.payload),
    },
    removeAvailability: {
      reducer: (state, action) => [
        ...state.filter((arr) => arr.id !== action.payload.id),
      ],
    },
    addAvailability: {
      reducer: (state, action) => {
        return [
          ...state.filter((arr) => arr.id !== action.payload.id),
          action.payload,
        ];
      },
      prepare: (obj) => {
        return {
          payload: {
            ...obj,
            startTime: obj.startTime.seconds,
            endTime: obj.endTime.seconds,
            createdAt: obj.createdAt?.seconds || 0,
          },
        };
      },
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAvailability, addAvailability, removeAvailability } =
  availabilitySlice.actions;

export default availabilitySlice.reducer;
