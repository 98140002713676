import React from "react";
import { Page, PageContent } from "grommet";
import HeaderComponent from "../Common/Header";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
import FullCalendarView from "./FullCalendarView";

const AvailabilityPage = () => {
  useCompanyInfo();

  return (
    <Page kind="wide">
      <HeaderComponent />
      <PageContent margin={{ top: "medium" }}>
        <FullCalendarView />
      </PageContent>
    </Page>
  );
};

export default AvailabilityPage;
