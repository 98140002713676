import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export const getAccountName = async (uid) => {
  const docRef = doc(firestore, "users", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().name;
  } else {
    return null;
  }
};
