import { createSlice } from "@reduxjs/toolkit";

export const calendarSlice = createSlice({
  name: "calendar",
  initialState: [],
  reducers: {
    selectTimeframe: {
      reducer: (state, action) => (state = action.payload),
    },
    clearTimeframe: {
      reducer: () => {
        return {};
      },
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectTimeframe, clearTimeframe } = calendarSlice.actions;

export default calendarSlice.reducer;
