import AddEventType from "../components/Account/AddEventType";
import EventTypes from "../components/Account/EventTypes";
import SettingsLandingPage from "../components/Account/SettingsLandingPage";
import AvailabilityPage from "../components/Availabilities/AvailabilityPage";
import DashboardPage from "../components/Dashboard/DashboardPage";
import ErrorPage from "../components/Defaults/ErrorPage";
import SetupPage from "../components/Setup/SetupPage";
import EditEventType from "../components/Account/EditEventType";
import AuthLandingPage from "../components/Common/AuthLandingPage";
import { EmailTemplateList } from "../components/Account/EmailTemplateList.tsx";

export const secureRoutes = {
  path: "/auth",
  element: <AuthLandingPage />,
  errorElement: <ErrorPage />,
  children: [
    {
      path: "setup",
      element: <SetupPage />,
    },
    {
      path: "dashboard",
      element: <DashboardPage />,
    },
    {
      path: "add-availability",
      element: <AvailabilityPage />,
    },
    {
      path: "settings/event-types/add",
      element: <AddEventType />,
    },
    {
      path: "settings/event-types/:id",
      element: <EditEventType />,
    },
    {
      path: "settings",
      element: <SettingsLandingPage />,
      children: [
        {
          path: "event-types",
          element: <EventTypes />,
          name: "Event Types",
        },
        {
          path: "email-templates",
          element: <EmailTemplateList />,
          name: "Email Templates",
        },
      ],
    },
  ],
};
