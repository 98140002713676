import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import {
  Box,
  Button,
  FormField,
  Page,
  PageContent,
  TextArea,
  TextInput,
} from "grommet";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
import { firestore } from "../../firebase";
import HeaderComponent from "../Common/Header";
const AddEventType = () => {
  useCompanyInfo();

  const company = useSelector(
    (state) => state.companyInfo.data.companyId || ""
  );

  const { handleSubmit, register } = useForm({
    defaultValues: {
      eventTypeName: "",
      typeDescription: "",
    },
  });

  const addNewEventType = async (data) => {
    console.log(data);
    const docRef = await addDoc(
      collection(firestore, "companies", company, "services"),
      {
        serviceId: (data.eventTypeName).toLowerCase(),
        serviceName: data.eventTypeName,
        description: data.typeDescription,
        visibility: "public",
        createdAt: serverTimestamp(),
      }
    );

    console.log("Document written with ID: ", docRef.id);
  };

  const initialCreateEventType = async (data) => {
    await addNewEventType(data);
  };

  return (
    <Page kind="wide">
      <HeaderComponent />
      <PageContent margin={{ top: "medium" }}>
        <form onSubmit={handleSubmit(initialCreateEventType)}>
          <Box direction="column" flex align="start" gap="medium">
            <Box direction="row" gap="small" align="end" flex>
              <FormField label="Event Type Name">
                <TextInput
                  placeholder="Untitled event type"
                  {...register("eventTypeName", { required: true })}
                />
              </FormField>
              {/* <Button label="Save" /> */}
            </Box>
            <Box direction="row" gap="small" align="end" flex>
              <FormField label="Event Description">
                <TextArea
                  placeholder="This is an event for you!"
                  {...register("typeDescription", { required: true })}
                />
              </FormField>
              {/* <Button label="Save" /> */}
            </Box>
            <Box direction="row" gap="small" align="start" flex>
              <Button label="Next Step" type="submit" />
            </Box>
          </Box>
        </form>
      </PageContent>
    </Page>
  );
};

export default AddEventType;
