import React, { useEffect, useState } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { Close, Save } from "grommet-icons";
import LoadingView from "../Common/LoadingView";
import { DateTime } from "luxon";

const CalendarEventClickedEdit = ({ type, eventId, closeLayer }) => {
  console.log(type, eventId);
  const [eventData, setEventData] = useState({
    serviceName: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const docRef = doc(
        firestore,
        type === "available" || type === "available-private"
          ? "availabilities"
          : "bookings",
        eventId
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setEventData(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
      }
    };

    getData();
  }, [type, eventId]);

  useEffect(() => {
    if (eventData.serviceName) {
      setLoading(false);
    }
  }, [eventData.serviceName]);

  // const selectPaymentConfirmed = async () => {
  //   const eventRef = doc(firestore, "bookings", eventId);
  //   await updateDoc(eventRef, {
  //     bookingStatus: "paid-confirmed",
  //     updatedAt: serverTimestamp(),
  //   });
  // };

  // const deleteBookingSlot = async () => {
  //   const slotRef = doc(firestore, "bookings", eventId);
  //   await deleteDoc(slotRef);
  //   closeLayer();
  // };

  // const deleteAvailSlot = async () => {
  //   const slotRef = doc(firestore, "availabilities", eventId);
  //   await deleteDoc(slotRef);
  //   closeLayer();
  // };

  // const cancelBookingSpot = async () => {
  //   const slotRef = doc(firestore, "bookings", eventId);
  //   const dataUpdate = {
  //     bookingStatus: "canceled",
  //   };
  //   await updateDoc(slotRef, dataUpdate);
  //   closeLayer();
  // };

  // const editBookingSpot = async (data) => {
  //   const slotRef = doc(firestore, "bookings", eventId);
  // };

  return loading ? (
    <LoadingView />
  ) : (
    <Layer onEsc={() => closeLayer()} onClickOutside={() => closeLayer()} modal>
      <Box fill="vertical" overflow="auto" pad="medium" gap="small">
        <Box direction="row" alignSelf="end">
          <Button
            icon={<Save color="#878787" />}
            hoverIndicator
            onClick={() => closeLayer()}
          />
          <Button
            icon={<Close color="#878787" />}
            hoverIndicator
            onClick={() => closeLayer()}
          />
        </Box>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Box direction="column" gap="small">
          <Box direction="row" gap="small" justify="between">
            <Text>Event ID</Text>
            <Text>{eventId}</Text>
          </Box>
          <Box direction="row" gap="small" justify="between">
            <Text>Time</Text>
            <Text>
              {DateTime.fromSeconds(eventData.startTime.seconds).toLocaleString(
                DateTime.TIME_SIMPLE
              )}{" "}
              -{" "}
              {DateTime.fromSeconds(eventData.endTime.seconds).toLocaleString(
                DateTime.TIME_SIMPLE
              )}
            </Text>
          </Box>
        </Box>
        {/* </form> */}
      </Box>
    </Layer>
  );
};

export default CalendarEventClickedEdit;
