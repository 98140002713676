import React from "react";
import {
  Box,
  FormField,
  Heading,
  Page,
  PageContent,
  TextInput,
  TextArea,
  Button,
} from "grommet";
import HeaderComponent from "../Common/Header";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
// import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const EditEventType = () => {
  useCompanyInfo();

  // const company = useSelector(
  //   (state) => state.companyInfo.data.companyId || ""
  // );

  // const eventTypeInfo = useSelector(
  //   (state) => state.companyInfo.services || []
  // );

  const { handleSubmit, register } = useForm({
    defaultValues: {
      eventTypeName: "",
      typeDescription: "",
      packages: [],
      contracts: [],
      price: "",
    },
  });

  // useEffect(() => {
  //   reset(
  // });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Page kind="wide">
      <HeaderComponent />
      <PageContent margin={{ top: "medium" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box direction="column" flex align="start" gap="medium">
            <Box direction="row" gap="small" align="end" flex>
              <FormField label="Event Type Name">
                <TextInput
                  placeholder="Untitled event type"
                  {...register("eventTypeName", { required: true })}
                />
              </FormField>
              {/* <Button label="Save" /> */}
            </Box>
            <Box direction="row" gap="small" align="end" flex>
              <FormField label="Event Description">
                <TextArea
                  placeholder="This is an event for you!"
                  {...register("typeDescription", { required: true })}
                />
              </FormField>
              {/* <Button label="Save" /> */}
            </Box>
            <Box direction="row" gap="small" align="end" flex>
              <Heading level="3">Packages</Heading>
            </Box>
            <Box direction="row" gap="small" align="start" flex>
              <Button label="Next Step" type="submit" />
            </Box>
          </Box>
        </form>
      </PageContent>
    </Page>
  );
};

export default EditEventType;
