export const statusMsg = (status) => {
  switch (status) {
    case "available":
      return "Available";
    case "available-private":
      return "Available";
    case "reserved":
      return "Reserved";
    case "booked":
      return "Booked";
    case "pending":
      return "Booking";
    default:
      return "Available";
  }
};
