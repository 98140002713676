import { Anchor, Box, Header, Image, Nav } from "grommet";
import { Logout, SettingsOption } from "grommet-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/ORIANGI.svg";

const HeaderComponent = () => {
  const navigate = useNavigate();
  return (
    <Header
      background="nav-bar-background"
      border={{
        color: "nav-bar-border",
        size: "xsmall",
        style: "solid",
        side: "bottom",
      }}
    >
      <Box align="center" direction="row" margin={{ left: "medium" }}>
        <Image src={logo} />
      </Box>
      <Nav direction="row" pad="small" align="center" gap="large">
        <Anchor onClick={() => navigate("/auth/dashboard")} label="Overview" />
        <Anchor
          label="Availability"
          onClick={() => navigate("/auth/add-availability")}
        />
        <Anchor label="Analytics" onClick={() => {}} />
      </Nav>
      <Nav direction="row" pad="small" align="center">
        <Anchor
          icon={<SettingsOption />}
          onClick={() => navigate("/auth/settings")}
        />
        <Anchor icon={<Logout />} onClick={() => navigate("/logout")} />
      </Nav>
    </Header>
  );
};

export default HeaderComponent;
