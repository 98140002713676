import { useEffect } from "react";
import { firestore } from "../firebase";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyInfo,
  setCompanyServices,
} from "../features/companyInfoSlice";

export const useCompanyInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.uid);
  const companyId = useSelector((state) => state.companyInfo.data?.companyId);

  useEffect(() => {
    console.log("running background services...");

    const getDefaultCompany = async () => {
      const userQuery = doc(firestore, "users", user);
      const userData = await getDoc(userQuery);
      return userData.data().memberOf;
    };

    const getCompanyInfo = async (compId) => {
      const coId = Object.keys(compId)[0];
      const compQuery = doc(firestore, "companies", coId);
      const compData = await getDoc(compQuery);
      dispatch(setCompanyInfo(compData.data()));
    };

    const getCompanyServices = async (compId) => {
      let servicesArray = [];
      const coId = Object.keys(compId)[0];
      const servQuery = query(
        collection(firestore, "companies", coId, "services")
      );
      const querySnapshot = await getDocs(servQuery);
      querySnapshot.forEach((doc) => {
        servicesArray.push({
          ...doc.data(),
          id: doc.id,
          createdAt:
            doc.data().createdAt?.seconds || doc.data()?.createdAt || null,
        });
      });
      dispatch(setCompanyServices(servicesArray));
    };

    if (user && !companyId) {
      getDefaultCompany().then((companyId) => {
        getCompanyInfo(companyId);
        getCompanyServices(companyId);
      });
    }
  }, [user, dispatch, companyId]);
};
