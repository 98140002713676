import React from "react";
import { Box, Card, CardBody, DataChart, Heading, Stack, Text } from "grommet";

const EarningsPanel = () => {
  const data = [
    {
      date: "2023-01-31",
      amount: 100,
      forecast: 0,
    },
    {
      date: "2023-02-29",
      amount: 200,
      forecast: 0,
    },
    {
      date: "2023-03-31",
      amount: 250,
      forecast: 0,
    },
    {
      date: "2023-04-30",
      amount: 100,
      forecast: 0,
    },
    {
      date: "2023-05-31",
      amount: 200,
      forecast: 0,
    },
    {
      date: "2023-06-30",
      amount: 250,
      forecast: 300,
    },
  ];
  return (
    <Box direction="column" gap="medium">
      <Box direction="column">
        <Heading level="3" margin={{ left: "medium" }}>
          Earnings
        </Heading>
        <Card background="analytics-card-background">
          <CardBody pad="medium">
            <DataChart
              data={data}
              series={[
                {
                  property: "date",
                  render: (date) => (
                    <Text margin={{ horizontal: "xsmall" }}>
                      {new Date(date).toLocaleDateString("en-US", {
                        month: "long",
                      })}
                    </Text>
                  ),
                },
                { property: "amount", prefix: "$" },
                "forecast",
              ]}
              chart={[
                {
                  property: [
                    { property: "amount", thickness: "medium" },
                    {
                      property: "forecast",
                      thickness: "medium",
                      opacity: "medium",
                    },
                  ],
                  type: "bars",
                  round: true,
                },
              ]}
              axis={{
                x: { property: "date" },
                y: { property: "amount", granularity: "fine" },
              }}
              guide={{ y: { granularity: "fine" } }}
              legend={{ side: "right" }}
              margin="none"
              bounds={{
                x: [0, 1000],
                y: [0, 800],
              }}
              detail
            />
          </CardBody>
        </Card>
      </Box>
      <Box direction="column">
        <Heading level="3" margin={{ left: "medium" }}>
          This Month
        </Heading>
        <Box direction="row" gap="small">
          <Stack anchor="top-right">
            <Card background="analytics-card-background" width="small">
              <CardBody pad="medium">
                <Text>Booked Amount</Text>
                <Heading level="2" margin={{ top: "10px", bottom: "5px" }}>
                  $2,350
                </Heading>
              </CardBody>
            </Card>
            <Box
              background="stack-analytics"
              pad="small"
              round
              margin={{ top: "-15px", right: "20px" }}
            >
              <Text color="#FFFFFF">✨ All Time High</Text>
            </Box>
          </Stack>
          <Card background="analytics-card-background" width="small">
            <CardBody pad="medium">
              <Text>Booked Sessions</Text>
              <Heading level="2" margin={{ top: "10px", bottom: "5px" }}>
                15
              </Heading>
            </CardBody>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default EarningsPanel;
