import React from "react";
import { Box, FormField, Button, CheckBox } from "grommet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setSessionDurations } from "../../features/companySetupSlice";
import { firestore } from "../../firebase";
import { doc, runTransaction, writeBatch } from "firebase/firestore";

const WelcomeFormPage4 = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.companySetup);
  const user = useSelector((state) => state.user.uid);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      "30min": false,
      "45min": false,
      "1hour": false,
      custom: false,
    },
  });
  const onSubmit = async (data) => {
    dispatch(setSessionDurations(data));

    const batch = writeBatch(firestore);
    const companyRef = doc(firestore, "companies", companyData.companyId);
    batch.set(companyRef, {
      companyId: companyData.companyId,
      companyName: companyData.companyName,
      ownerName: companyData.ownerName,
    });

    const updateUserCompany = doc(firestore, "users", user);
    batch.update(updateUserCompany, {});

    await runTransaction(async (t) => {
      const newCompany = await t.set(
        doc(firestore, "companies", companyData.companyId, {
          companyId: companyData.companyId,
          companyName: companyData.companyName,
          ownerName: companyData.ownerName,
        })
      );
      console.log(newCompany.data());
    });

    // send the data to Firestore
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box gap="medium">
        <FormField
          label="How long is each session?"
          error={errors.type && errors.type?.message}
        />
        <Controller
          name="30min"
          control={control}
          render={({ field }) => <CheckBox {...field} label="30 minutes " />}
        />
        <Controller
          name="45min"
          control={control}
          render={({ field }) => <CheckBox {...field} label="45 minutes" />}
        />
        <Controller
          name="1hour"
          control={control}
          render={({ field }) => <CheckBox {...field} label="1 hour" />}
        />
        <Controller
          name="custom"
          control={control}
          render={({ field }) => <CheckBox {...field} label="custom" />}
        />
      </Box>
      <Box direction="row" gap="medium" margin={{ top: "small" }}>
        <Button type="submit" primary label="Setup Company >" />
      </Box>
    </form>
  );
};

export default WelcomeFormPage4;
