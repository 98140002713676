import React from "react";
import { Box, Button, Text } from "grommet";
import { StatusGood, StatusPlaceholder } from "grommet-icons";
import { DateTime } from "luxon";
import { getFunctions, httpsCallable } from "firebase/functions";

const BookingProgressItem = ({ item, bookingInfo }) => {
  const functions = getFunctions();
  const getBookingReminderApi = httpsCallable(
    functions,
    "processUpcomingBookingReminder"
  );

  const processReminderEmail = async (status) => {
    const result = await getBookingReminderApi({
      bookingId: bookingInfo.id,
      statusId: status.id,
      customerEmail: bookingInfo.customerEmail,
      companyName: bookingInfo.companyName,
      companyLogo: bookingInfo.companyLogo,
      customerName: bookingInfo.customerName,
      location: bookingInfo.location[0].name,
      serviceName: bookingInfo.serviceName,
      startTime: bookingInfo.startTime,
      endTime: bookingInfo.endTime,
      customerTz: bookingInfo.customerTz,
      balance:
        bookingInfo?.balance && bookingInfo?.balance !== 0
          ? bookingInfo.balance
          : null,
      addOns: bookingInfo?.addOns || null,
    });
    return result;
  };

  const sendReminder = (status) => {
    processReminderEmail(status)
      .then((result) => {
        if (result.data.result === "success") {
          console.log("reminder sent successfully");
        } else {
          console.log("reminder failed to send");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return item ? (
    <Box gap="small">
      {item
        .sort((a, b) => a.order - b.order)
        .map((status) => {
          return (
            <Box direction="row" gap="small" key={status.id}>
              {status.completed ? <StatusGood /> : <StatusPlaceholder />}
              <Box direction="column">
                <Text>{status.name}</Text>
                {status.completed && (
                  <Text size="small">
                    Completed{" "}
                    {DateTime.now().minus(status.timestamp.seconds).diff < 10
                      ? DateTime.now()
                          .minus(status.timestamp.seconds)
                          .toRelative()
                      : DateTime.fromSeconds(status.timestamp.seconds).toFormat(
                          "DDDD"
                        )}
                  </Text>
                )}
                {!status.completed && status.name === "Reminder Email" && (
                  <Button
                    label="Send Reminder"
                    onClick={() => sendReminder(status)}
                  />
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  ) : (
    <Box direction="row" gap="small">
      <Text>No status available</Text>
    </Box>
  );
};

export default BookingProgressItem;
