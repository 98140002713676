import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import companySetupReducer from "./features/companySetupSlice";
import companyInfoReducer from "./features/companyInfoSlice";
import availabilityReducer from "./features/availabilitiesSlice";
import calendarReducer from "./features/calendarSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    companySetup: companySetupReducer,
    companyInfo: companyInfoReducer,
    availability: availabilityReducer,
    calendar: calendarReducer,
  },
});
