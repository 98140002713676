import React, { useEffect, useState } from "react";
import { Box, Button, Heading, Layer, List, Text } from "grommet";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { Layer as LayerIcon } from "grommet-icons";
import { firestore } from "../../firebase";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import LoadingView from "../Common/LoadingView";
import BookingProgressItem from "../Bookings/BookingProgressItem";

const UpcomingView = () => {
  const compId = useSelector(
    (state) => state.companyInfo.data?.companyId || null
  );
  const [nextSession, setNextSession] = useState();
  const [showDetails, setShowDetails] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentSeconds = DateTime.now().toJSDate();

    const bookingsRef = collection(firestore, "bookings");

    const getBookingData = async () => {
      let docId;
      const q = query(
        bookingsRef,
        where("companyId", "==", compId),
        where("startTime", ">", currentSeconds),
        orderBy("startTime"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setNextSession({
          ...doc.data(),
          id: doc.id,
        });
        docId = doc.id;
      });
      return docId;
    };

    const getBookingStatus = async (bookId) => {
      let statusArr = [];
      const statusQuery = query(
        collection(firestore, "bookings", bookId, "status"),
        orderBy("order")
      );
      const querySnapshot = await getDocs(statusQuery);
      querySnapshot.forEach((doc) => {
        statusArr.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setNextSession((nextSession) => ({
        ...nextSession,
        status: statusArr,
      }));
      setLoading(false);
    };

    if (compId) {
      getBookingData().then((id) => {
        if (id) {
          getBookingStatus(id);
        } else {
          setNextSession();
          setLoading(false);
        }
      });
    } else {
      console.log("No Company Data");
    }
  }, [compId]);

  const markAsCompleted = async (item) => {
    console.log(item);
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Box margin={{ top: "medium" }}>
      <Heading level={2}>Welcome Back!</Heading>

      {nextSession ? (
        <Text>
          Your next booking is on{" "}
          {DateTime.fromSeconds(nextSession.startTime.seconds).toFormat(
            "DDDD @ h:mm a "
          )}
          with {nextSession.customerName}.{" "}
          <Button
            label="Open Details"
            onClick={() => setShowDetails(true)}
            reverse
            icon={<LayerIcon />}
            secondary
          />
        </Text>
      ) : (
        <Text>You have no upcoming sessions. Enjoy the break!</Text>
      )}

      {showDetails && (
        <Layer
          position="center"
          onEsc={() => setShowDetails(false)}
          onClickOutside={() => setShowDetails(false)}
        >
          <Box margin="medium" direction="row" gap="medium">
            <Box direction="column">
              <Text>Name: {nextSession.customerName}</Text>
              <Text>Email: {nextSession.customerEmail}</Text>
              <Text>Phone: {nextSession.customerPhone}</Text>
            </Box>
            <Box direction="column">
              <Heading level={3} margin={{ top: "0", bottom: "small" }}>
                Client Progress
              </Heading>
              {nextSession.hasOwnProperty("status") ? (
                <Text>No progress yet</Text>
              ) : (
                <List
                  data={nextSession.status}
                  onClickItem={(event) => {
                    markAsCompleted(event.item);
                  }}
                >
                  {(datum) => <BookingProgressItem item={datum} />}
                </List>
              )}
            </Box>
          </Box>
          <Button
            margin={{ top: "medium" }}
            label="close"
            onClick={() => setShowDetails(false)}
            alignSelf="center"
          />
        </Layer>
      )}
    </Box>
  );
};

export default UpcomingView;
