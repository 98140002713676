import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Layer,
  Notification,
  Text,
} from "grommet";
import { Share } from "grommet-icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCompanyInfo } from "../../apis/useCompanyInfo";

const EventTypes = () => {
  useCompanyInfo();

  const [shareLayer, setShareLayer] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [visible, setVisible] = useState();

  const eventTypesList = useSelector(
    (state) => state.companyInfo.services || []
  );

  const companyId = useSelector(
    (state) => state.companyInfo.data?.companyId || ""
  );

  useEffect(() => {});

  const openShareLink = (link) => {
    setSelectedEvent(`https://cx.oriangi.com/${companyId}/${link}`);
    setShareLayer(true);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(selectedEvent);
    setVisible(true);
  };

  const onCloseShare = () => setShareLayer(false);

  return (
    <>
      {visible && <Notification toast title="Copied!" time={4000} />}

      <Box pad="large">
        <Grid
          gap="medium"
          rows="small"
          columns={{ count: "fill", size: "medium" }}
        >
          {eventTypesList.map((evt) => {
            return (
              <Card key={evt.id} overflow={{ vertical: "scroll" }}>
                <CardHeader pad="medium">
                  <strong>{evt.serviceName}</strong> {evt.serviceId}
                </CardHeader>
                <CardBody pad="medium">
                  <Text>{evt.visibility}</Text>
                </CardBody>
                <CardFooter pad="medium">
                  <Box direction="row" justify="between" flex align="center">
                    <Link to={evt.id}>Edit event type</Link>
                    <Button
                      icon={<Share />}
                      label="Share"
                      onClick={() => openShareLink(evt.id)}
                    />
                  </Box>
                </CardFooter>
              </Card>
            );
          })}

          <Card width="small" background="oriangi-pink">
            <CardHeader pad="medium">Event Type +</CardHeader>
            <CardBody pad="medium">
              <Link to="./add">Add</Link>
            </CardBody>
          </Card>
        </Grid>

        {shareLayer && (
          <Layer
            position="center"
            onClickOutside={onCloseShare}
            onEsc={onCloseShare}
          >
            <Box pad="medium" flex align="center">
              <Text>{selectedEvent}</Text>
              <Button onClick={() => copyLink()} label="Copy Link" />
            </Box>
          </Layer>
        )}
      </Box>
    </>
  );
};

export default EventTypes;
