import { Page, PageContent } from "grommet";
import React, { useEffect, useState } from "react";
import HeaderComponent from "../Common/Header";
// import PendingActionView from "./PendingActionView";
import UpcomingView from "./UpcomingView";
import { useSelector } from "react-redux";
import LoadingView from "../Common/LoadingView";
import { useCompanyInfo } from "../../apis/useCompanyInfo";
import BookingPage from "../Bookings/BookingPage";
import EarningsPanel from "./EarningsPanel";

const DashboardPage = () => {
  useCompanyInfo();

  const [loading, setLoading] = useState(true);
  const companyId = useSelector((state) => state.companyInfo.data?.companyId);

  useEffect(() => {
    if (companyId !== null) {
      setLoading(false);
    }
  }, [companyId]);

  return loading ? (
    <LoadingView />
  ) : (
    <Page kind="wide" background="oriangi-pink">
      <HeaderComponent />
      <PageContent>
        <UpcomingView />
        {/* <PendingActionView /> */}
        <BookingPage />
        <EarningsPanel />
      </PageContent>
    </Page>
  );
};

export default DashboardPage;
