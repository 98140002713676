import { Box, Grommet, Page, PageContent, Text } from "grommet";
import React from "react";
import AuthGoogle from "../../auth/AuthGoogle";
import { firebaseAuth } from "../../firebase";
import PublicHeader from "../Common/PublicHeader";
import { customTheme } from "../../theme";
import { Link } from "react-router-dom";

const LoginPage = () => {
  return (
    <Grommet theme={customTheme} full>
      <Page fill>
        <PublicHeader />
        <PageContent>
          <AuthGoogle auth={firebaseAuth} />
          <Box
            direction="column"
            alignSelf="center"
            gap="medium"
            pad={{ top: "large", horizontal: "xlarge" }}
          >
            <Text textAlign="center">Don't have an account?</Text>
            <Text textAlign="center">
              <Link to="/signup">Join Oriangi for Free Today</Link>
            </Text>
          </Box>
        </PageContent>
      </Page>
    </Grommet>
  );
};

export default LoginPage;
