import { Box, ResponsiveContext, Text } from "grommet";
import React from "react";

const Footer = ({ ...rest }) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          direction="row"
          justify="between"
          border={{ side: "top", color: "light-4" }}
          pad={{ top: "xlarge" }}
          {...rest}
        >
          <Box gap="large" align="start">
            <Box
              gap="small"
              direction="row-responsive"
              align="center"
              pad={{ horizontal: "small" }} // align with margin of icons from SocialMedia
            >
              {/* <Image src={logo} /> */}
              {size !== "small" && size !== "xsmall" && (
                <Text weight="bold" size="large">
                  Coming soon!
                </Text>
              )}
            </Box>
          </Box>
          {/* <FooterContent /> */}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Footer;
