import React from "react";
import { Box, FormField, Button, CheckBox } from "grommet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyEventTypes,
  setPage,
} from "../../features/companySetupSlice";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../firebase";

const WelcomeFormPage2 = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.company);

  const addData = async (data) => {
    const docRef = await addDoc(
      collection(firestore, "companies", companyData.companyId, "services"),
      data
    );

    return {
      [docRef.id]: data,
    };
  };

  const newServicesRef = async (services) => {
    let serviceIds = [];

    for (let i = 0; i < services.length; i++) {
      const dataObj = {
        serviceId: services[i],
        companyId: companyData.companyId,
        serviceName: services[i].charAt(0).toUpperCase() + services[i].slice(1),
      };

      const result = await addData(dataObj);

      serviceIds.push(result);
    }

    return serviceIds;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      family: false,
      events: false,
      portraits: false,
    },
  });

  const onSubmit = (data) => {
    const addServices = Object.keys(data).filter((key) => data[key] === true);

    newServicesRef(addServices)
      .then((servicesCreated) => {
        dispatch(setCompanyEventTypes(servicesCreated));
      })
      .then(() => {
        dispatch(setPage(3));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box gap="medium">
        <FormField
          label="What type of photography do you provide?"
          error={errors.type && errors.type?.message}
        />
        <Controller
          name="family"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Family" />}
        />
        <Controller
          name="events"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Events" />}
        />
        <Controller
          name="portraits"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Portraits" />}
        />
      </Box>
      <Box direction="row" gap="medium" margin={{ top: "small" }}>
        <Button type="submit" primary label="Next >" />
      </Box>
    </form>
  );
};

export default WelcomeFormPage2;
