import React, { useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../firebase";

interface EmailTemplate {
  id: string;
  createdAt: number;
  html: string;
  subject: string;
}

export const EmailTemplateList: React.FC = () => {
  useEffect(() => {
    let templateArray: EmailTemplate[] = [];
    const fetchItems = async () => {
      const emailTempQuery = query(collection(firestore, "email-templates"));
      const querySnapshot = await getDocs(emailTempQuery);
      querySnapshot.forEach((doc) => {
        templateArray.push({
          html: doc.data().html,
          subject: doc.data().subject,
          id: doc.id,
          createdAt:
            doc.data().createdAt?.seconds || doc.data()?.createdAt || null,
        });
      });
    };

    fetchItems();

    console.log(templateArray);
  }, []);

  return <></>;
};
