import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { firebaseAuth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import LoadingView from "./LoadingView";
import { setUser } from "../../features/userSlice";
import { getAccountName } from "../../apis/getAccountName";

const LoginStatus = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        dispatch(setUser(user.uid));
        getAccountName(user.uid).then((name) => {
          setLoggedIn(name);
        });
        setLoading(false);
      } else {
        setLoggedIn(false);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Box direction="row" gap="xxsmall" justify="center">
      <Text>
        {loading ? (
          <LoadingView />
        ) : loggedIn ? (
          <Link to="/auth/dashboard">Welcome, {loggedIn}!</Link>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </Text>
    </Box>
  );
};

export default LoginStatus;
