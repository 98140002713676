import React from "react";
import { Box, Heading, Paragraph } from "grommet";

const MainContent = ({ size, ...rest }) => {
  return (
    <>
      <Box direction="row" {...rest}>
        <Heading size={size}>Oriangi</Heading>
      </Box>
      <Paragraph margin={{ top: "none" }} textAlign="center" size="xxlarge">
        Simple platform, streamlined profits.
      </Paragraph>
    </>
  );
};

export default MainContent;
