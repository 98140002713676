import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Layer,
  Text,
} from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { addBooking } from "../../features/companyInfoSlice";
import { DateTime } from "luxon";
import LoadingView from "../Common/LoadingView";
import BookingProgressItem from "./BookingProgressItem";

const BookingPage = () => {
  const [loading, setLoading] = useState(true);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [booking, setBooking] = useState(null);

  const dispatch = useDispatch();
  const company = useSelector((state) => state.companyInfo.data?.companyId);
  const bookings = useSelector((state) => state.companyInfo.bookings);

  useEffect(() => {
    const getBookingData = async () => {
      const bookingsRef = collection(firestore, "bookings");
      const q = query(
        bookingsRef,
        where("companyId", "==", company),
        where("startTime", ">", DateTime.now().toJSDate())
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        dispatch(
          addBooking({
            ...doc.data(),
            id: doc.id,
          })
        );
      });
    };

    if (company) {
      getBookingData();
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [company, dispatch]);

  const getBookingStatus = async (id) => {
    const statusRef = collection(firestore, "bookings", id, "status");
    const q = query(statusRef, orderBy("order", "desc"));
    const querySnapshot = await getDocs(q);
    const status = [];
    querySnapshot.forEach((doc) => {
      status.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    return status;
  };

  const openCurrentDetail = (bk) => {
    getBookingStatus(bk.id)
      .then((status) => {
        setCurrentDetail(status);
        setBooking(bk);
      })
      .then(() => {
        setShowDetail(true);
      });
  };

  return loading ? (
    <LoadingView />
  ) : (
    <>
      <Heading level="3">Upcoming Bookings</Heading>
      <Grid
        columns={{
          count: 4,
          size: "auto",
        }}
        gap="small"
        fill
      >
        {bookings?.map((bk) => {
          return (
            <Box direction="column" key={bk.id}>
              <Card width="medium">
                <CardHeader pad="medium" background="light-2">
                  {bk.customerName} {bk.serviceName}
                </CardHeader>
                <CardBody pad="medium" gap="small">
                  <Box>
                    {DateTime.fromSeconds(bk.startTime).toFormat("DDDD")}
                  </Box>
                  <Box>
                    {`${DateTime.fromSeconds(bk.startTime).toFormat(
                      "h:mm a - "
                    )} 
                  ${DateTime.fromSeconds(bk.endTime).toFormat("h:mm a")}`}
                  </Box>
                  <Box>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        bk.location[0].address
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bk.location[0].name}
                    </a>
                  </Box>
                </CardBody>
                <CardFooter pad="small" background="light-1" direction="row">
                  <Box
                    background="green"
                    round="small"
                    pad={{ horizontal: "small" }}
                  >
                    <Text color="white">{bk.bookingStatus}</Text>
                  </Box>
                  <Box justify="end" direction="row">
                    {/* <Button icon={<Chat color="light-5" />} hoverIndicator /> */}
                    <Button
                      label="View Details"
                      onClick={() => openCurrentDetail(bk)}
                    />
                  </Box>
                </CardFooter>
              </Card>
            </Box>
          );
        })}
      </Grid>
      {showDetail && (
        <Layer
          position="center"
          onEsc={() => setShowDetail(false)}
          onClickOutside={() => setShowDetail(false)}
        >
          <Box pad="medium">
            <BookingProgressItem item={currentDetail} bookingInfo={booking} />
          </Box>
        </Layer>
      )}
    </>
  );
};

export default BookingPage;
