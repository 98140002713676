import { Box, Grommet } from "grommet";
import { customTheme } from "../../theme";
import React from "react";
import MainContent from "./MainContent";
import Section from "./Section";
import NavHeader from "./NavHeader";
import Footer from "./Footer";

const HomeLandingPage = () => {
  return (
    <Grommet theme={customTheme} full>
      <NavHeader />
      <Box align="center" pad="large">
        <MainContent size="xlarge" />
      </Box>
      <Section>
        <Footer />
      </Section>
    </Grommet>
  );
};

export default HomeLandingPage;
