import React from "react";
import { Box } from "grommet";

const Section = ({ children, width, ...rest }) => {
  return (
    <Box align="center" pad="large" {...rest}>
      <Box width={width}>{children}</Box>
    </Box>
  );
};

export default Section;
