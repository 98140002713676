import React from "react";
import { Box, FormField, Button, CheckBox } from "grommet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setEventDaysOfWeek, setPage } from "../../features/companySetupSlice";

const WelcomeFormPage3 = () => {
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  });
  const onSubmit = (data) => {
    dispatch(setEventDaysOfWeek(data));
    dispatch(setPage(4));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box gap="medium">
        <FormField
          label="What are the usual days of the week you provide Family photography sessions?"
          error={errors.type && errors.type?.message}
        />
        <Controller
          name="monday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Monday" />}
        />
        <Controller
          name="tuesday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Tuesday" />}
        />
        <Controller
          name="wednesday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Wednesday" />}
        />
        <Controller
          name="thursday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Thursday" />}
        />
        <Controller
          name="friday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Friday" />}
        />
        <Controller
          name="saturday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Saturday" />}
        />
        <Controller
          name="sunday"
          control={control}
          render={({ field }) => <CheckBox {...field} label="Sunday" />}
        />
      </Box>
      <Box direction="row" gap="medium" margin={{ top: "small" }}>
        <Button type="submit" primary label="Next >" />
      </Box>
    </form>
  );
};

export default WelcomeFormPage3;
