import React from "react";
import { Box, FormField, TextInput, Button } from "grommet";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setNewCompany, setPage } from "../../features/companySetupSlice";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

const WelcomeFormPage1 = () => {
  const userId = useSelector((state) => state.user.uid);
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data, userId);

    const companyNew = {
      companyId: data.companyHandle,
      companyName: data.companyName,
      ownerName: data.fullName,
      ownerId: userId,
    };

    await setDoc(doc(firestore, "companies", data.companyHandle), companyNew);

    dispatch(setNewCompany(companyNew));

    dispatch(setPage(2));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box gap="medium">
        <FormField
          label="Your Full Name"
          error={errors.fullName && errors.fullName?.message}
        >
          <TextInput
            placeholder="John Doe"
            {...register("fullName", {
              required: "Full name is required.",
            })}
          />
        </FormField>
        <FormField
          label="Company Name"
          error={errors.companyName && errors.companyName?.message}
        >
          <TextInput
            placeholder="Happy Photography"
            {...register("companyName", {
              required: "Company name is required.",
            })}
          />
        </FormField>
        <FormField
          label="Company Handle"
          error={errors.companyHandle && errors.companyHandle?.message}
        >
          <TextInput
            placeholder="happy-photography"
            {...register("companyHandle", {
              required: "Company handle / ID is required.",
            })}
          />
        </FormField>
      </Box>
      <Box direction="row" gap="medium" margin={{ top: "small" }}>
        <Button type="submit" primary label="Next >" />
        <Button type="reset" label="Clear" />
      </Box>
    </form>
  );
};

export default WelcomeFormPage1;
