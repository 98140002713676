import { createBrowserRouter } from "react-router-dom";
import Logout from "../auth/Logout";
import { secureRoutes } from "./secureRoutes";
import HomeLandingPage from "../components/Homepage/HomeLandingPage";
import LoginPage from "../components/Account/LoginPage";
import SignUpPage from "../components/Account/SignUp";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLandingPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  secureRoutes,
]);
