import React from "react";
import { Box, Text, Spinner } from "grommet";

const LoadingView = ({ text }) => {
  return (
    <Box align="center" pad="large" gap="small">
      <Spinner />
      {text ? <Text>{text}</Text> : <Text>Loading...</Text>}
    </Box>
  );
};

export default LoadingView;
