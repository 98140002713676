import { Grommet } from "grommet";
import React, { useEffect, useState } from "react";
import { customTheme } from "../../theme";
import { firebaseAuth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import LoadingView from "./LoadingView";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/userSlice";

const AuthLandingPage = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const pathData = useMatches();
  const navigate = useNavigate();

  // check if firebase user is logged in or not
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        dispatch(setUser(user.uid));
        setLoading(false);
        if (pathData.length === 1 && pathData[0].pathname === "/auth") {
          navigate("/auth/dashboard");
        }
      } else {
        setLoading(false);
        if (pathData[0].pathname !== "/login") {
          navigate("/login");
        }
      }
    });
    return () => unsubscribe();
  }, [dispatch, navigate, pathData]);

  return (
    <Grommet theme={customTheme} full>
      {loading ? <LoadingView text="Checking you are human :)" /> : <Outlet />}
    </Grommet>
  );
};

export default AuthLandingPage;
