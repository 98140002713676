import { createSlice } from "@reduxjs/toolkit";

export const companyInfoSlice = createSlice({
  name: "companyInfo",
  initialState: {
    services: null,
    data: null,
    bookings: [],
  },
  reducers: {
    setCompanyInfo: (state, action) => {
      state.data = action.payload;
    },
    setCompanyServices: (state, action) => {
      state.services = action.payload;
    },
    addBooking: {
      reducer: (state, action) => {
        state.bookings = [
          ...state.bookings.filter((arr) => arr.id !== action.payload.id),
          action.payload,
        ];
      },
      prepare: (obj) => {
        return {
          payload: {
            ...obj,
            startTime: obj.startTime.seconds,
            endTime: obj.endTime.seconds,
            createdAt: obj.createdAt?.seconds || 0,
            updatedAt: obj.updatedAt?.seconds || 0,
          },
        };
      },
    },
    // update company services specifically
  },
});

// Action creators are generated for each case reducer function
export const { setCompanyInfo, setCompanyServices, addBooking } =
  companyInfoSlice.actions;

export default companyInfoSlice.reducer;
