import React, { useEffect, useState } from "react";
import { Box, Button, Layer, Text } from "grommet";
import {
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { Close, Edit } from "grommet-icons";
import LoadingView from "../Common/LoadingView";
import { statusMsg } from "../../helpers/statusMessages";

const CalendarEventClickedView = ({ type, eventId, closeLayer, editLayer }) => {
  const [eventData, setEventData] = useState({
    serviceName: null,
  });
  const [loading, setLoading] = useState(true);
  const [paymentDisabled, setPaymentDisabled] = useState(false);

  console.log(eventData);

  useEffect(() => {
    const getData = async () => {
      const docRef = doc(
        firestore,
        type === "available" || type === "available-private"
          ? "availabilities"
          : "bookings",
        eventId
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log(docSnap.data());
        setEventData(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
      }
    };

    getData();
  }, [type, eventId]);

  useEffect(() => {
    if (eventData.serviceName) {
      setLoading(false);
    }
  }, [eventData.serviceName]);

  const selectPaymentConfirmed = async () => {
    const eventRef = doc(firestore, "bookings", eventId);
    await updateDoc(eventRef, {
      bookingStatus: "paid-confirmed",
      updatedAt: serverTimestamp(),
    });
    setPaymentDisabled(true);
  };

  const deleteBookingSlot = async () => {
    const slotRef = doc(firestore, "bookings", eventId);
    await deleteDoc(slotRef);
    closeLayer();
  };

  const deleteAvailSlot = async () => {
    const slotRef = doc(firestore, "availabilities", eventId);
    await deleteDoc(slotRef);
    closeLayer();
  };

  const cancelBookingSpot = async () => {
    const slotRef = doc(firestore, "bookings", eventId);
    const dataUpdate = {
      bookingStatus: "canceled",
    };
    await updateDoc(slotRef, dataUpdate);
    closeLayer();
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Layer onEsc={() => closeLayer()} onClickOutside={() => closeLayer()} modal>
      <Box fill="vertical" overflow="auto" pad="medium" gap="small">
        <Box direction="row" alignSelf="end">
          <Button
            icon={<Edit color="#878787" />}
            hoverIndicator
            onClick={() => editLayer()}
          />
          <Button
            icon={<Close color="#878787" />}
            hoverIndicator
            onClick={() => closeLayer()}
          />
        </Box>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Box direction="column" gap="small">
          <Box direction="row" gap="small" justify="between">
            <Text>Event ID</Text>
            <Text>{eventId}</Text>
          </Box>
          <Box direction="row" gap="small" justify="between">
            <Text>Event Type</Text>
            <Text>{eventData.serviceName}</Text>
          </Box>
          <Box direction="row" gap="small" justify="between">
            <Text>Location</Text>
            <Text>
              {eventData.hasOwnProperty("locations")
                ? eventData.locations[0].name
                : eventData.location[0].name}
            </Text>
          </Box>
          {eventData.status && (
            <Box direction="row" gap="small" justify="between">
              <Text>Booking Status</Text>
              <Text>{statusMsg(eventData.status)}</Text>
            </Box>
          )}
          {type === "available-private" && type !== "available" && (
            <Box direction="row" gap="small" justify="between">
              <Text>Reserved for</Text>
              <Text>{eventData.name}</Text>
            </Box>
          )}
          {type !== "available" && type !== "available-private" ? (
            <Box gap="small">
              <Box direction="row" gap="small" justify="between">
                <Text>Payment Status</Text>
                <Text>{eventData.bookingStatus}</Text>
              </Box>
              <Box direction="row" gap="small" justify="between">
                <Text>Name</Text>
                <Text>{eventData.customerName}</Text>
              </Box>
              <Box direction="row" gap="small" justify="between">
                <Text>Phone</Text>
                <Text>{eventData.customerPhone}</Text>
              </Box>
              <Box direction="row" gap="small" justify="between">
                <Text>Email</Text>
                <Text>{eventData.customerEmail}</Text>
              </Box>
              <Box direction="row" gap="small">
                <Button
                  label="Mark Payment Confirmed"
                  primary
                  onClick={() => selectPaymentConfirmed()}
                  disabled={
                    eventData.bookingStatus === "paid-confirmed"
                      ? true
                      : paymentDisabled
                  }
                />
              </Box>
              <Box direction="row" gap="small">
                <Button
                  label="Delete Booking & Availability"
                  primary
                  onClick={() => deleteBookingSlot()}
                />
                <Button
                  label="Cancel Booking"
                  primary
                  onClick={() => cancelBookingSpot()}
                />
              </Box>
            </Box>
          ) : (
            <Box direction="row" gap="small">
              <Button
                label="Delete Availability"
                primary
                onClick={() => deleteAvailSlot()}
              />
            </Box>
          )}
        </Box>
        {/* </form> */}
      </Box>
    </Layer>
  );
};

export default CalendarEventClickedView;
