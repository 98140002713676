import { Box, Text, Heading, Page, PageContent } from "grommet";
import React from "react";
import { useSelector } from "react-redux";
import WelcomeFormPage1 from "./WelcomeFormPage1";
import WelcomeFormPage2 from "./WelcomeFormPage2";
import WelcomeFormPage3 from "./WelcomeFormPage3";
import WelcomeFormPage4 from "./WelcomeFormPage4";

const SetupPage = () => {
  const currentSetupPage = useSelector(
    (state) => state.companySetup.setup.page
  );

  const WelcomeFormPage = () => {
    switch (currentSetupPage) {
      case 1:
        return <WelcomeFormPage1 />;
      case 2:
        return <WelcomeFormPage2 />;
      case 3:
        return <WelcomeFormPage3 />;
      case 4:
        return <WelcomeFormPage4 />;
      default:
        return <WelcomeFormPage1 />;
    }
  };

  return (
    <Page background={{ color: "#DCC7BE" }}>
      <PageContent>
        <Box
          direction="row-responsive"
          pad="medium"
          justify="center"
          align="center"
          fill="vertical"
        >
          <Box width="large">
            <Heading>Hello, welcome to Oriangi</Heading>
            <Text>To get started, we would like to get to know you better</Text>
          </Box>
          <Box width="medium">
            <WelcomeFormPage />
          </Box>
        </Box>
      </PageContent>
    </Page>
  );
};

export default SetupPage;
