import { createSlice } from "@reduxjs/toolkit";

export const companySetupSlice = createSlice({
  name: "companySetup",
  initialState: {
    ownerName: null,
    ownerId: null,
    companyName: null,
    companyId: null,
    ownerPhone: null,
    companyWeb: null,
    eventTypes: null,
    eventDaysOfWeek: null,
    sessionDuration: null,
    setup: {
      page: 1,
    },
  },
  reducers: {
    setNewCompany: (state, action) => {
      state.ownerName = action.payload?.ownerName || state.ownerName;
      state.ownerId = action.payload?.ownerId || state.ownerId;
      state.companyName = action.payload?.companyName || state.companyName;
      state.companyId = action.payload?.companyId || state.companyId;
      state.ownerPhone = action.payload?.ownerPhone || state.ownerPhone;
      state.companyWeb = action.payload?.companyWeb || state.companyWeb;
    },
    setCompanyEventTypes: (state, action) => {
      state.eventTypes = action.payload;
    },
    setEventDaysOfWeek: (state, action) => {
      state.eventDaysOfWeek = action.payload;
    },
    setSessionDurations: (state, action) => {
      state.sessionDuration = action.payload;
    },
    setPage: (state, action) => {
      state.setup.page = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNewCompany,
  setCompanyEventTypes,
  setEventDaysOfWeek,
  setPage,
  setSessionDurations,
} = companySetupSlice.actions;

export default companySetupSlice.reducer;
