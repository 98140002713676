import React, { useEffect } from "react";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import firebase from "firebase/compat/app";
import "./firebaseui-styling.global.css"; // Import globally. Not with CSS modules.

const AuthGoogle = (props) => {
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(props.auth);

    // // Is there an email link sign-in?
    // if (ui.isPendingRedirect()) {
    //   ui.start("#firebaseui-auth-container", uiConfig);
    // }

    ui.start(".firebase-auth-container", {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
          // signInMethod:
          //   firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        },
      ],
      signInSuccessUrl: "/auth",
      privacyPolicyUrl: "https://www.origani.com/privacy",
    });
  }, [props.auth]);

  return <div className="firebase-auth-container"></div>;
};

export default AuthGoogle;
