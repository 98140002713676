import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux";
import CalendarNewEventView from "./CalendarNewEventView";
import { selectTimeframe } from "../../features/calendarSlice";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  addAvailability,
  removeAvailability,
} from "../../features/availabilitiesSlice";
import { firestore } from "../../firebase";
import { DateTime } from "luxon";
import CalendarEventClickedView from "./CalendarEventClickedView";
import CalendarEventClickedEdit from "./CalendarEventClickedEdit";

const FullCalendarView = () => {
  const [createEventView, setCreateEventView] = useState();
  const [eventClicked, setEventClicked] = useState();
  const [eventClickedView, setEventClickedView] = useState();
  const [eventEditView, setEventEditView] = useState();
  const calendarRef = useRef();
  const dispatch = useDispatch();
  const calendarAvails = useSelector((state) => state.availability);
  const companyIdData = useSelector(
    (state) => state.companyInfo.data?.companyId
  );

  useEffect(() => {
    let unsubscribe;

    if (companyIdData && calendarRef) {
      const calendarApi = calendarRef.current.getApi();

      const q = query(
        collection(firestore, "availabilities"),
        where("companyId", "==", companyIdData),
        where("startTime", ">=", new Date(calendarApi.view.activeStart))
      );

      const getBooking = async (id) => {
        const docRef = doc(firestore, "bookings", id);
        const docSnap = await getDoc(docRef);
        return {
          customerName: docSnap.data().customerName,
          customerPhone: docSnap.data().customerPhone,
          customerEmail: docSnap.data().customerEmail,
          startTime: docSnap.data().startTime.seconds,
          endTime: docSnap.data().endTime.seconds,
        };
      };

      unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach(async (change) => {
          if (change.type === "added") {
            const dataObj = {
              ...change.doc.data(),
              id: change.doc.id,
              ...(change.doc.data().status === "reserved" && {
                customerData: await getBooking(change.doc.id),
              }),
            };
            dispatch(addAvailability(dataObj));
          } else if (change.type === "removed") {
            const dataObj = {
              id: change.doc.id,
            };
            dispatch(removeAvailability(dataObj));
          }
          // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
          // console.log(source, "data: ", doc.data());
        });
      });
      return () => unsubscribe();
    }
  }, [companyIdData, dispatch, calendarRef]);

  const handleDateSelect = (eventClicked) => {
    dispatch(
      selectTimeframe({
        start: eventClicked.startStr,
        end: eventClicked.endStr,
      })
    );
    setCreateEventView(true);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        {eventInfo.event.extendedProps.type === "available-private" ? (
          <b>{eventInfo.event.extendedProps.name}</b>
        ) : eventInfo.event.extendedProps.type === "reserved" ? (
          <b>{eventInfo.event.extendedProps.customerName}</b>
        ) : (
          <b>{eventInfo.event.title}</b>
        )}
        <br />
        {eventInfo.timeText}
      </>
    );
  };

  const renderEventsArray = calendarAvails.map((item) => ({
    id: item.id,
    title: item.serviceName,
    start: DateTime.fromSeconds(
      item.customerData ? item.customerData.startTime : item.startTime
    ).toISO(),
    end: DateTime.fromSeconds(
      item.customerData ? item.customerData.endTime : item.endTime
    ).toISO(),
    color:
      item.status === "available-private" || item.status === "reserved"
        ? "#0B4F6C"
        : "rgba(148, 216, 255, 1)",
    extendedProps: {
      type: item.status,
      ...(item.name && { name: item.name }),
      ...(item.customerData && {
        customerName: item.customerData.customerName,
      }),
    },
  }));

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek",
        }}
        selectable={true}
        editable={true}
        allDaySlot={true}
        selectMirror={true}
        eventContent={renderEventContent}
        select={handleDateSelect}
        events={renderEventsArray}
        eventClick={(info) => {
          setEventClicked({
            id: info.event.id,
            ...info.event.extendedProps,
          });
          setEventClickedView(true);
        }}
      />
      {createEventView && (
        <CalendarNewEventView closeLayer={() => setCreateEventView(false)} />
      )}
      {eventClickedView && (
        <CalendarEventClickedView
          type={eventClicked.type}
          closeLayer={() => setEventClickedView(false)}
          eventId={eventClicked.id}
          editLayer={() => {
            setEventClickedView(false);
            setEventEditView(true);
          }}
        />
      )}
      {eventEditView && (
        <CalendarEventClickedEdit
          type={eventClicked.type}
          closeLayer={() => setEventEditView(false)}
          eventId={eventClicked.id}
        />
      )}
    </>
  );
};

export default FullCalendarView;
