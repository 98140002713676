import { Anchor, Box, Image, ResponsiveContext } from "grommet";
import React from "react";
import logo from "../../assets/ORIANGI.svg";
import LoginStatus from "../Common/LoginStatus";

const NavHeader = () => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          direction="row"
          justify="between"
          alignSelf="center"
          gap="medium"
          pad={{ top: "large", horizontal: "xlarge" }}
        >
          <Anchor href="/" icon={<Image src={logo} />} color="black" />
          <LoginStatus />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default NavHeader;
