import React, { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LoadingView from "../components/Common/LoadingView";

const Logout = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      signOut(auth);
      setTimeout(() => {
        navigate("../");
      }, 1500);
    } catch {
      console.log("Error logging out");
    }
  });
  return (
    <>
      <LoadingView text="Logging out..." />
    </>
  );
};

export default Logout;
