import React from "react";
import { Anchor, Box, Image } from "grommet";
import logo from "../../assets/ORIANGI.svg";

const PublicHeader = () => {
  return (
    <Box margin="medium">
      <Box
        direction="row"
        justify="between"
        alignSelf="center"
        gap="medium"
        pad={{ top: "large", horizontal: "xlarge" }}
      >
        <Anchor href="/" icon={<Image src={logo} />} color="black" />
      </Box>
    </Box>
  );
};

export default PublicHeader;
