import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBEOeLrKg-5DBvzpFd8k_N5GwMjUdkc0ZU",
  authDomain: "oriangi.firebaseapp.com",
  projectId: "oriangi",
  storageBucket: "oriangi.appspot.com",
  messagingSenderId: "628125113827",
  appId: "1:628125113827:web:1b860a37b41a5c67eb14d6",
  measurementId: "G-CBRRNQML8D",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const firebaseAuth = getAuth(app);
