import React from "react";
import {
  Box,
  Button,
  DateInput,
  FormField,
  MaskedInput,
  Select,
  TextInput,
  Tip,
  Layer,
  CheckBox,
} from "grommet";
import { useForm, Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useSelector } from "react-redux";
import { CircleInformation, Close } from "grommet-icons";

const CalendarNewEventView = ({ closeLayer }) => {
  const servicesList = useSelector((state) => state.companyInfo.services);
  const companyData = useSelector((state) => state.companyInfo.data);
  const calendarData = useSelector((state) => state.calendar);

  const { control, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      price: "",
      eventType: "",
      sessionType: "",
      startTime: calendarData.start,
      endTime: calendarData.end,
      setDate: calendarData.start.substring(0, 10),
      location: "",
      privateSessionBool: false,
    },
  });

  const eventTypeSelected = watch("eventType");
  const startTimeValue = watch("startTime");
  const endTimeValue = watch("endTime");
  const setDateValue = watch("setDate");
  const privateSessionSelected = watch("privateSessionBool");

  const addAvailableDateTime = async (data) => {
    const docRef = await addDoc(collection(firestore, "availabilities"), {
      companyId: companyData.companyId,
      currentPrice: parseFloat(data.price.substring(1)),
      durationId: data.sessionType.split("|")[0],
      endTime: data.endDateTime,
      locations: [
        {
          address: data.location,
          name: data.location,
        },
      ],
      originalPrice: parseFloat(data.price.substring(1)),
      servUniqueId: data.eventType.split("|")[0],
      serviceId: data.eventType.split("|")[1],
      serviceName: data.eventType.split("|")[2],
      startTime: data.startDateTime,
      status: data.privateSessionBool ? "available-private" : "available",
      createdAt: serverTimestamp(),
      ...(data.privateSessionBool && { name: data.reservedSessionName }),
      ...(data.privateSessionBool && { email: data.reservedSessionEmail }),
      ...(data.privateSessionBool && { phone: data.reservedSessionPhone }),
    });

    console.log("Document written with ID: ", docRef.id);
  };

  const onSubmit = (data) => {
    const prepareDataSend = {
      ...data,
      startDateTime:
        data.startTime.length < 9
          ? DateTime.fromFormat(
              `${data.setDate} ${data.startTime}`,
              "y-MM-dd h:mm a"
            ).toJSDate()
          : DateTime.fromISO(data.startTime).toJSDate(),
      endDateTime:
        data.endTime.length < 9
          ? DateTime.fromFormat(
              `${data.setDate} ${data.endTime}`,
              "y-MM-dd h:mm a"
            ).toJSDate()
          : DateTime.fromISO(data.endTime).toJSDate(),
    };
    addAvailableDateTime(prepareDataSend);
    closeLayer();
  };
  const handleStartTimeChange = (e) => setValue("startTime", e.target.value);
  const handleEndTimeChange = (e) => setValue("endTime", e.target.value);
  const handleDateChange = (e) => {
    setValue("setDate", new Date(e.value).toISOString());
  };

  const mapDurations = (data) => {
    return data.map((e) => {
      return {
        value: `${e.id}|${e.minutesLength}`,
        label: e.name,
      };
    });
  };

  const eventTypeSelectors = servicesList?.map((ev) => {
    return {
      value: `${ev.id}|${ev.serviceId}|${ev.serviceName}`,
      label: `${ev.serviceName} - ${ev.serviceId}`,
    };
  });

  const sessionDurationList =
    eventTypeSelected !== ""
      ? mapDurations(
          servicesList.find((ser) => ser.id === eventTypeSelected.split("|")[0])
            .durations
        )
      : [];

  return (
    <Layer onEsc={() => closeLayer()} onClickOutside={() => closeLayer()} modal>
      <Box fill="vertical" overflow="auto" pad="medium">
        <Button
          icon={<Close color="#878787" />}
          hoverIndicator
          onClick={() => closeLayer()}
          alignSelf="end"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box direction="column" gap="small">
            <FormField label="Event Type">
              <Controller
                name="eventType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelKey="label"
                    valueKey={{ key: "value", reduce: true }}
                    options={eventTypeSelectors || []}
                  />
                )}
              />
            </FormField>

            {eventTypeSelected && (
              <FormField label="Package">
                <Controller
                  name="sessionType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelKey="label"
                      valueKey={{ key: "value", reduce: true }}
                      options={sessionDurationList || []}
                    />
                  )}
                />
              </FormField>
            )}

            {eventTypeSelected && (
              <FormField label="Reserved Session">
                <CheckBox
                  {...register("privateSessionBool")}
                  label="Yes, this is a reserved session"
                />
              </FormField>
            )}

            {privateSessionSelected && (
              <>
                <FormField label="Who is the reserved session for?">
                  <TextInput {...register("reservedSessionName")} />
                </FormField>
                <Box direction="row" gap="small">
                  <FormField label="Email Address">
                    <TextInput {...register("reservedSessionEmail")} />
                  </FormField>
                  <FormField label="Phone">
                    <TextInput {...register("reservedSessionPhone")} />
                  </FormField>
                </Box>
              </>
            )}

            <FormField label="Session Date / Time">
              <Box direction="row" gap="medium">
                <DateInput
                  format="mm/dd/yyyy"
                  onChange={handleDateChange}
                  value={setDateValue}
                />

                <MaskedInput
                  mask={[
                    {
                      length: [1, 2],
                      options: Array.from({ length: 12 }, (v, k) => k + 1),
                      regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                      placeholder: "hh",
                    },
                    { fixed: ":" },
                    {
                      length: 2,
                      options: ["00", "15", "30", "45"],
                      regexp: /^[0-5][0-9]$|^[0-9]$/,
                      placeholder: "mm",
                    },
                    { fixed: " " },
                    {
                      length: 2,
                      options: ["am", "pm"],
                      regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                      placeholder: "ap",
                    },
                  ]}
                  value={
                    startTimeValue.length < 9
                      ? startTimeValue
                      : DateTime.fromISO(startTimeValue).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )
                  }
                  onChange={handleStartTimeChange}
                />

                <MaskedInput
                  mask={[
                    {
                      length: [1, 2],
                      options: Array.from({ length: 12 }, (v, k) => k + 1),
                      regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                      placeholder: "hh",
                    },
                    { fixed: ":" },
                    {
                      length: 2,
                      options: ["00", "15", "30", "45"],
                      regexp: /^[0-5][0-9]$|^[0-9]$/,
                      placeholder: "mm",
                    },
                    { fixed: " " },
                    {
                      length: 2,
                      options: ["am", "pm"],
                      regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                      placeholder: "ap",
                    },
                  ]}
                  value={
                    endTimeValue.length < 9
                      ? endTimeValue
                      : DateTime.fromISO(endTimeValue).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )
                  }
                  onChange={handleEndTimeChange}
                />
              </Box>
            </FormField>

            <Box border={true} margin={{ top: "small" }} />

            <FormField label="Location">
              <TextInput
                {...register("location", {
                  required: true,
                })}
                placeholder="Address"
              ></TextInput>
            </FormField>

            <Box border={true} margin={{ top: "small" }} />

            <FormField
              label={
                <Box direction="row" gap="small">
                  <>Price</>
                  <Tip
                    content="Price set automatically from event type"
                    dropProps={{ align: { left: "right" } }}
                  >
                    <CircleInformation />
                  </Tip>
                </Box>
              }
            >
              <MaskedInput
                mask={[{ fixed: "$" }, { regexp: /^\d*\.?\d*$/ }]}
                {...register("price", { required: true })}
              />
            </FormField>
          </Box>
          <Box align="end" gap="medium" margin={{ top: "small" }}>
            <Button type="submit" primary label="Save" />
          </Box>
        </form>
      </Box>
    </Layer>
  );
};

export default CalendarNewEventView;
