import React from "react";
import { Page, PageContent, Tab, Tabs } from "grommet";
import { Outlet, useNavigate } from "react-router-dom";
import { secureRoutes } from "../../routes/secureRoutes";
import HeaderComponent from "../Common/Header";

const SettingsLandingPage = () => {
  const navigate = useNavigate();

  return (
    <Page kind="wide">
      <HeaderComponent />
      <PageContent>
        {/* <Grid
          columns={["1/4", "3/4"]}
          rows={[]}
          gap="small"
          areas={[["sidebar", "main"]]}
          margin={{ top: "medium" }}
        >
          <Nav
            direction="column"
            gridArea="sidebar"
            background="nav-bar-border"
            pad="medium"
            align="start"
            round
          >
            {secureRoutes.children
              .find((item) => item.path === "settings")
              .children.map((links) => (
                <Link to={links.path} key={links.name}>
                  <Button
                    label={links.name}
                    active={links.path === active}
                    onClick={() => setActive(links.path)}
                    fill
                  />
                </Link>
              ))}
          </Nav>

          <Box gridArea="main" margin={{ left: "medium" }}>
            <Outlet />
          </Box>
        </Grid> */}
        <Tabs alignControls="start">
          {secureRoutes.children
            .find((item) => item.path === "settings")
            .children.map((links) => (
              <Tab
                title={links.name}
                key={links.path}
                onClick={() => navigate(links.path)}
              >
                <Outlet />
              </Tab>
            ))}
        </Tabs>
      </PageContent>
    </Page>
  );
};

export default SettingsLandingPage;
